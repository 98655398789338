var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5c2e4cd75a5b893293945afcb5b8bb4b608f6245"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole, ExtraErrorData } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/browser";

const NODE_ENV = process.env.NODE_ENV;
const NEXT_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

if (!!SENTRY_DSN) {
  const integrations = [
    new CaptureConsole({
      levels: ["error"],
    }),
    new ExtraErrorData({
      depth: 3,
    }),
    new BrowserTracing(),
  ];

  Sentry.init({
    environment: NEXT_ENV || NODE_ENV,
    dsn: SENTRY_DSN,
    tracesSampleRate: 0,
    sampleRate: 1.0,
    integrations,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
  });
}
